.hero {
  padding: 0 0 3.25em;
  text-align: center;

  font-size: .8em;

  @media all and (min-width: $small-screen) {
    font-size: 1em;
  }
  .container {
    max-width: 875px;
  }
  h1 {
    margin-bottom: 1.5rem;
    line-height: .9;

    strong {
      color: $color-blue-dark;
    }
  }
  .largetext {
    font-size: 1.2em;
  }
  a {
    &, &:link, &:visited {
      border-bottom: 3px solid $color-blue;
      color: inherit;
      font-weight: inherit;
    }
    &:hover, &:active, &:focus {
      color: $hover-color;
      border-color: $hover-color;
    }
  }
}
.statistics {
  background: url($image-path + 'rounded-top.svg') no-repeat bottom;
  background-size: 100% auto;

  @media all and (min-width: 1920px) {
    background: url($image-path + 'rounded-top-stretched.svg') no-repeat bottom;
    background-size: 100vw auto;
  }
}

.company-feature {
  background: $color-blue-darker;
  padding: 2em 0 100px;
  margin-top: -1px; // makes sure there is no gap in background color;

  text-align: center;
  color: $color-white;

  @media all and (min-width: $small-screen) {
    padding: 5.25em 0 200px;
  }

  h2 {
    margin-top: 0;
    color: inherit;
    font-size: 2.75em;
    line-height: 1;

    @media all and (min-width: $small-screen) {
      font-size: 3.75em;
    }
  }
  h3 {
    margin-top: 0;
    color: inherit;
    font-size: 1.5em;

    @media all and (min-width: $small-screen) {
      font-size: 2em;
    }
  }
  .button a, a.button {
    &, &:link, &:visited {
      margin: 0 5px 15px;
      padding: .75em 3.5em;
      width: 100%;
      text-align: center;

      @media all and (min-width: $small-screen) {
        width: auto;
      }
    }
    &:hover, &:active, &:focus {
      background: $color-blue;
      color: $color-blue-darker;
    }
  }

  a {
    &, &:link, &:visited {
      color: $color-blue-light;
    }
    &:hover, &:active, &:visited {
      color: $color-white;
    }
  }
  .company-intro {
    margin-bottom: 1em;
  }
}
.stay-informed {
  text-align: center;

  margin-bottom: 2.5em;

  @media all and (min-width: $small-screen) {
    font-size: 1.2em;
    margin-bottom: 1.5em;
  }
  .ri-icon {
    margin: -50px auto -10px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: linear-gradient(to bottom,
                rgba($color-blue-pale, 1) 0%,
                rgba($color-blue-pale, 1) 40%,
                rgba($color-blue-pale, 0) 50%);
    border-radius: 50%;
    height: 175px;
    width: 175px;

    img {
      max-width: 80px;
    }

    @media all and (min-width: $small-screen) {
      margin: -75px auto -25px;
      height: 275px;
      width: 275px;

      img {
        max-width: 125px;
      }
    }
  }

  .container {
    max-width: 780px;
  }
  div.button {
    padding-top: 1em;
  }
  h2 {
    margin-top: 0;
    font-size: 3em;
  }
  a {
    &, &:link, &:visited {
      border-bottom: 3px solid $color-blue;
      color: inherit;
      font-weight: inherit;
    }
    &:hover, &:active, &:focus {
      color: $hover-color;
      border-color: $hover-color;
    }
  }
}
