// BREAKPOINTS
$xsmall-screen: em(500);
$small-screen: em(700);
$medium-screen: em(950);
$large-screen: em(1200);
$xlarge-screen: em(1440);

// WIDTHS
$max-width: rem(1020);

// PATHS
// $image-path: "../images/";
// $font-path: "../fonts/fonts/";

// LIVE PATHS
$image-path: "/images/";
$font-path: "/fonts/fonts/";


// COLORS
$color-blue-pale: #e6f2ff;
$color-blue-light: #a0d9ff;
$color-blue: #0099ea;
$color-blue-dark: #0180c4;
$color-blue-gradient: #004E92;
$color-blue-darker: #0d1933;
$color-navy: #050f25;

$color-grey-light: #DEE0E1;
$color-grey: #2e3950;
$color-grey-dark: #0d1933;

$color-offwhite: #f1f6fc;
$color-white: #fff;
$color-black: #000;



// BACKGROUNDS
$bkgd-body: $color-blue-pale;


// FONTS
$main-font: 'Inter', sans-serif;
$accent-font: 'Markazi Text', serif;

// FONT STYLES
$font-color: $color-grey;

$font-size: 1em;
$min-font: 14px;
$max-font: 16px;
$main-lineheight: 1.6;

$link-color: $color-blue-dark;
$hover-color: $color-blue-darker;

$hr-color: $color-grey-light;
$border-color: $hr-color;

$highlight-color: rgba($color-blue, 0.2);
