@media print {

  
  .banner,
  button.menu,
  .fullScreenMenu,
  .admin-links,
  .secondary-nav,
  .footer {
    display: none !important;
  }

  .mast {
    position: relative;
    .container {
      display: block;
      max-width: 100%;
    }
    .logo {
      margin: 1em 0;
      transform: none;
    }
  }

  .maininfo {
    padding: 2em 0;
    border: 0;

    .container { display: block; }
  }

  a.button, a.button:link, a.button:visited,
  .button a, .button a:link, .button a:visited,
  input.button, button.button {
    border: 2px solid $link-color !important;
    color: $link-color !important; 
  }

  .callout {
    border: 1px solid $font-color;
  }
  .hero-mask {
    height: auto;
    min-height: initial;
  }
  .button-list {
    li {
      margin: 0 0 .5em;
    }
  }
}
