*,*:after,*:before{box-sizing:border-box;}

/**
 * Including the at-only style with the dialog CSS as it is required
 * to visually hide a dialog's heading if desired.
 */
.at-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

/**
 * Trigger styling
 */
[data-modal-open][hidden] {
  display: none;
}

/**
 * When a modal dialog is open, don't allow scrolling of content
 * beneath (on desktop). Also useful in negating instances of
 * double scroll bars.
 * (browser window + dialog if long enough content)
 */
body.modal-open {
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px); 
    z-index: 9; 
  }
}

/**
 * Modal Dialog base styling
 */
.js [data-modal],
.a11y-modal {
  -webkit-overflow-scrolling: touch;
  background: #fff;
  border: 2px solid;
  bottom: 0;
  box-shadow: 0 0 1800px 400px rgba(0,0,0,.4);
  left: 0;
  margin: auto;
  max-height: 80%;
  max-width: 900px;
  opacity: 1;
  overflow: auto;
  padding: 1.25em;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(0%);
  visibility: visible;
  width: 90%;
  z-index: 10; /* this should be more than enough... */
}

@media screen and ( min-height: 440px ) and ( min-width: 500px ) {
  .js [data-modal],
  .a11y-modal {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity .2s ease-in-out, transform .2s ease-in-out;
  }
}


/**
 * To allow for CSS animations, hidden modal dialogs are
 * not set to display: none, but rather the following rule
 * set, in combination with the dialog's default
 * position: fixed, will keep dialogs hidden from
 * all users until opened.
 *
 * This also solves an issue with iOS VO + Safari not allowing
 * modal dialogs to be focused, if the dialog is initially
 * set to "display: none".
 */
.js [data-modal][hidden],
.a11y-modal[hidden] {
  display: block;
  opacity: 0;
  pointer-events: none;
  transform: translateY(15vh);
  visibility: hidden;
}

.a11y-modal__close-btn {
  border: none;
}

.is-icon-btn {
  background: none;
  border: none;
  height: 2em;
  padding: 0;
  position: absolute;
  right: 1em;
  top: 1em;
  width: 2em;
}

.is-icon-btn:hover [data-modal-x],
.is-icon-btn:focus [data-modal-x] {
  color: #600;
  transform: scale(1.3) rotate(45deg);
  transition: transform .2s ease-in-out;
}

[data-modal-x] {
  border-right: 2px solid;
  bottom: 0;
  display: block;
  height: 2em;
  left: 0;
  margin: auto;
  pointer-events: none;
  position: relative;
  right: 0;
  top: 0;
  transform: rotate(45deg);
  width: 2px;
}

[data-modal-x]:after {
  border-right: 2px solid;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
  width: 100%;
}


// Custom styles 
.a11y-modal {
  outline: none;
  padding: 0;
  border: 0px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05), 
              0px 2px 20px rgba(0, 85, 184, 0.1), 
              0px 4px 50px rgba(0, 0, 0, 0.05), 
              0px 4px 100px rgba(0, 85, 184, 0.1);
  border-radius: 2px;

  color: $font-color;
  font-size: .875em;
  font-family: $main-font;

  h1 {
    display: block;
    background: $color-grey-dark;
    padding: .75rem 2rem;
    margin: 0;
  
    color: $color-white;
    font-size: 2.55em;
    font-family: $accent-font;
    font-weight: normal;
    line-height: 1;
  }
  h2, h3 {
    margin: .5em 0 .5em;

    color: $color-grey-dark;
    font-size: 1em;
    font-family: $main-font;
    font-weight: bold;
  }
  h2 {
    margin: 0 0 1.5em;
    font-size: 1.35em;
  }

  .is-icon-btn {
    height: 1em;
    width: 1em;
    top: 1.5em;
    right: 1.5em;

    [data-modal-x] {
      height: 1em;
      color: $color-white;
    }

    &:hover,
    &:focus {
      [data-modal-x] {
        color: $color-blue-light;
        transform: rotate(45deg);
      }
    }
  }

  .modal-content {
    @media screen and (min-width: $small-screen) {
      display: flex;
    }
  }
  .modal-about {
    flex: 1;
    padding: 2em;
  }
  .modal-details {
    flex: 0 1 30%;
    padding: 2em;
    background: $color-offwhite;
  }
  .button.sm-btn {
    img {
      margin-left: 5px;
    }
  }
  .arrow-link {
    &:after { 
      margin-top: -2px;
    }
  }
}