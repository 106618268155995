
.container {
  width: 90%;
  max-width: $max-width;
  margin: 0 auto;

  &.min-width {
    max-width: 800px;
  }

  &.fullwidth {
    max-width: $medium-screen;
  }
}

.hideTop {
  visibility: hidden;
  position: absolute;
  top: 0;

  height: 1px;
  font-size: 1px;
  line-height: 1px;
}

.hidden {
  display: none !important;
}
.Hidden {
  display: none !important;
}

.no-margin {
  margin: 0;
}
.nospacing {
	margin: 0px;
	padding: 0px;
}
.shadow-spacing {
  padding: 4px 4px 1em;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}


.cursorOn {
  cursor: pointer !important;
}

// Edit page scss
.edit {
  position: fixed;
  top: calc(50% - 60px);
  left: 0;
  background: black;
  border: 1px solid white;
  width: 22px;
  height: 120px;
  z-index: 10000;
  margin: 0 0 0 -1px;
  padding: 0;
  text-align: center;
  writing-mode: vertical-rl;

  a {
    display: block;
    color: white;
    font-size: 13px;
    font-family: sans-serif;
    line-height: 1;
    padding: 5px;

    &:hover {
      opacity: .75;
    }
  }
}
