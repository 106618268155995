.header {
    .logo {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    span.dlt {
        display: inline-block;
        margin-left: 2em;
        padding: 0 2em;
        border-left: solid 2px $color-blue;
        border-right: solid 2px $color-blue;

        img {
            max-width: 125px;
        }
    }
    span.state {
        display: inline-block;

        img {
            max-width: 125px;
        }
    }
    a.skills {
        display: inline-block;
        margin-left: 1.5em;
    }

}

.hero.backtobusiness {
    text-align: left;
    padding-bottom: 1em;
    background: none !important;

    .container {
        max-width: 63.75rem;
        h1,
        .largetext {
            text-align: center;
        }
        .largetext {
            strong {
                display: block;
                text-align: center;
                font-family: $accent-font;
                font-size: 32px;
            }
        }
    }
}

.eligibility.resources {
    .container {
        .resource-card {
            h2 {
                line-height: 1.1;
            }
        }
        ul {
          li {
              margin: 0 0 1em 0;
              list-style: none;
              display: flex;
              flex-flow: row nowrap;
              align-items: center;

              strong {
                  font-weight: 700;
              }

              .check-icon {
                  width: 20px;
                  min-width: 20px;
                  margin-right: 15px;

                  svg {
                      path {
                          fill: $color-blue;
                      }
                  }
              }
          }  
        }
    }
}

.button.button-large {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 2.5em;

    a {
        display: block;
        width: 50%;
        font-family: $accent-font !important;
        font-size: 1.8em !important;
        background: $color-blue-darker !important;
        color: $color-white !important;
        transition: all .3s ease-in-out;

        &:hover {
            background: $color-blue-gradient !important;
            box-shadow: 0 2px 20px rgba(0,0,0,.05),0 2px 20px rgba(0,85,184,.1),0 4px 50px rgba(0,0,0,.05),0 4px 100px rgba(0,85,184,.1);
        }
    }
}

// Form 
.total-requested {
    padding: 1em 0 ;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    #b2bTotalAmt {
        border: solid 1px $color-blue-darker;
        padding: 1em .5em;
        min-width: 75px;
        margin-left: 10px;
        border-radius: 2px;
    }
}

table#form-input-otherRecruitmentExpenses {
    
}