.resources {
  .container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3em;
  }
}
.resource-card {
  background: $color-white;
  box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.08), 
              0px 4px 36px rgba(0, 85, 184, 0.02), 
              0px 2px 12px rgba(49, 56, 80, 0.04);
  border-radius: 2px;

  flex: 0 1 100%;
  padding: 1.5em;
  margin: 0 0 1em;

  @media all and (min-width: $small-screen) {
    flex: 0 1 48%;
    margin: 0 1% 1em;
  }

  @media all and (min-width: $small-screen) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10 & IE11 CSS styles go here */
    flex: 0 1 42%;
  }
}