.pager {
	display: flex;
	justify-content: space-between;
	align-items: center;

	border-top: 1px solid $color-offwhite;
	padding: 2rem 1rem;

	font-size: .9em;
	line-height: 1;

	@media (max-width: $small-screen) {
		flex-direction: column;
		div { margin-bottom: 1em; }
	}
	
	form {
		display: flex;
		align-items: center;
	}
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: .25em .5em;
		padding-right: 16px;
    margin: 0 0 0 5px;

    border: 1px solid $color-grey-dark;
    border-radius: 2px;
    transition: all .1s ease-out;
		
    background: url($image-path + 'icon-chevron-down.svg') no-repeat right white;
		background-position: calc(100% - 6px) 8px;

		//font-size: 16px;
  }
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;

	color: $color-blue-dark;

	@media (max-width: $small-screen) {
		flex-wrap: wrap;
		justify-content: center;
	}

  img {
    height: 18px;
	}
	.current {
		font-weight: 500;
		margin: 0 4px;
	}

  a {
    &, &:link, &:visited {
			color: $font-color;
			font-weight: normal;

      display: inline-block;
			text-decoration: none;
			border: 0px;
			margin: 0 4px;
    }

    &:hover, &:active, &:focus {
			color: $hover-color;
      img {
				opacity: .75;
			}
    }
  }

	.middlePages {
    display: flex;
    justify-content: center;
		align-items: center;

		margin: 0 1em;
		text-align: center;

		@media (max-width: $small-screen) {
			order: 1;
			width: 100%;
			margin: 0 1em .75em;
		}
	}
	.firstPage, .lastPage {
		a {
			&, &:link, &:visited {
				color: $color-blue-dark;
			}
			&:hover, &:active, &:focus {
				color: $color-blue;
			}
		}
	}

	.firstPage {
		@media (max-width: $small-screen) {
			order: 2;
			margin-right: 6px;
		}
	}

	.lastPage {
		align-self: flex-end;

		@media (max-width: $small-screen) {
			order: 3;
			margin-left: 6px;
		}
	}
}
