.filters {
  margin-top: -1em;

  .advanced-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-self: center;

    text-align: center;
    margin-bottom: 2em;
  }

  .field {
    flex: 0 1 48%;

    @media all and (min-width: $small-screen) {
      flex: 0 1 24%;
    }
  }

  select.select {
    position: relative;
    display: block;
    margin: 0 0 15px;
    padding: 5px 10px;
    width: 100%;
    height: 45px;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url($image-path + 'icon-chevron-down.svg') no-repeat right white;
    background-position: calc(100% - 12px) 16px;
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.08), 0px 4px 36px rgba(0, 85, 184, 0.02), 0px 2px 12px rgba(49, 56, 80, 0.04);

    font-size: 16px;

    @media all and (min-width: $small-screen) {
      margin: 0 0 25px;
    }
  }

  .keyword {
    input[type=text], input[type=search] {
      width: 100%;
      height: 45px;
      margin: 0 0 15px;
      padding: 5px 10px 5px 32px;

      background: url($image-path + 'icon-keyword-search.svg') no-repeat right white;
      background-position: 12px 15px;

      -webkit-appearance:none;
      -moz-appearance: none;
      border: 1px solid transparent;
      border-radius: 2px;
      box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.08), 0px 4px 36px rgba(0, 85, 184, 0.02), 0px 2px 12px rgba(49, 56, 80, 0.04);
      transition: all .1s ease-out;

      font-size: 16px;

      &::placeholder {
        color: rgba($color-black, .33);
      }
      &:focus, &:active {
        outline: none;
        border: 1px solid $color-black;
        box-shadow: 0 1px 12px 0 rgba($color-black, .1),
      }
      @media all and (min-width: $small-screen) {
        margin: 0 0 25px;
      }
    }
  }


  .search-btns {
    flex: 0 0 100%;

    display: flex;
    flex-direction: column;

    @media all and (min-width: $small-screen) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }


    a.clear-search {
      &, &:link, &:visited {
        display: flex;
        justify-content: center;
        align-items: center;

        border: 0px;
        margin: 0 10px 25px;

        color: $color-blue-dark;
        font-weight: normal;

        img {
          width: 20px;
          height: 20px;
          margin-left: 5px;
        }
      }

      &:hover, &:active, &:focus {
        color: $color-blue-darker;
      }
    }
  }

}


.channel-filter {
  form {
    display: flex;
    align-items: center;
  
    position: relative;
    
    @media screen and (min-width: $small-screen) {  
      justify-content: flex-end;
    }
  }

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;

    padding-right: 100px;

    &:after {
      position: absolute;
      right: 0; 
      z-index: 0;

      display: inline-block;
      content: '';
      width: 58px;
      height: 32px;

      border-radius: 2px;
      background: $color-blue-pale;
    }
    &:before {
      position: absolute;
      z-index: 1;
      right: 30px;   

      display: inline-block;
      content: '';
      width: 24px;
      height: 24px;

      border-radius: 2px;
      background: #FFFFFF;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1), 
                  0px 2px 20px rgba(0, 85, 184, 0.1), 
                  0px 4px 50px rgba(0, 0, 0, 0.1), 
                  0px 4px 100px rgba(0, 85, 184, 0.1);
    }
  }
  input[type="checkbox"] {
    visibility: hidden;
    width: 1px;

    &:active, &:focus {
      ~ label {
        &:after {
          background: $color-blue-light;
        }
      }
    }

    &:checked {
      ~ label {
        &:after {
          background: $color-blue;
        }

        &:before {
          right: 5px;

          background: $color-white url($image-path + "icon-check-blue.svg") no-repeat;
          background-size: 12px 10px;
          background-position: center center;
        }
      }
    }
  }
}

.more-info {
  position: relative;
  display: inline-block;

  position: absolute;
  right: 70px;


  .tooltip {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    transform: translate(-46%, -110%);
    width: 200px;

    background: $color-white;
    border-radius: 5px;
    padding: .75em;

    text-align: center;
    color: $font-color;
    font-size: .9rem;
    line-height: 1.1;

    &:after {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: transparent;
      border-top-color: $color-white;
      border-width: 10px;
      margin-left: -10px;
      margin-top: -1px;
    }
  }
  img {
    &:hover {
      cursor: help;
    }
    &:active, &:focus {

      ~ .tooltip {
        visibility: visible;
      }
    }
  }
}