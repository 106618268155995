/************* HTML Styles *************/
*,
*::before,
*::after { box-sizing: inherit; }

@viewport { width:device-width; }

html {
  overflow-x: auto;
  overflow-y: scroll;

  // the google translate script affected the position of the footer ovals; adding this to the html fixed the issue
  @media (min-width: $xsmall-screen) {
    background-color: $bkgd-body;
    background-image: url($image-path + 'footer-ovals.png');
    background-position: bottom left;
    background-repeat: no-repeat;
  }
}
body {
  box-sizing: border-box;
  // overflow: hidden;
  background-color: $bkgd-body;

  color: $font-color;
  @include fluid-type($min-font, $max-font);
  font-family: $main-font;
  line-height: $main-lineheight;

  @media (min-width: $xsmall-screen) {
   background-image: url($image-path + 'header-ovals.png'),
                     url($image-path + 'footer-ovals.png');
   background-position:  top right,
                         bottom left;
   background-repeat: no-repeat;
 }
}

h1 {
  margin: 0 0 10px;

  color: $color-grey-dark;
  font-size: 4.9em;
  font-family: $accent-font;
  font-weight: 500;
  line-height: .9;
}

h2 {
  margin: 0 0 .35em 0;

  color: $color-grey-dark;
  font-size: 2.25em;
  font-family: $accent-font;
  font-weight: 400;
  line-height: 1.25;
}

h3 {
  margin: .95em 0 .25em;

  color: $color-grey-dark;
  font-size: 1.5em;
  font-family: $accent-font;
  font-weight: 400;
  line-height: 1.1;
}

h4 {
  margin: 0 0 0.7em;

  color: $color-grey-dark;
  font-size: .95em;
  font-family: $main-font;
  font-weight: 500;
  line-height: 1.1;
}

h5 {
  margin: 0 0 .5em;

  color: $font-color;
  font-size: 1.25em;
  font-family: $accent-font;
  font-weight: normal;
  line-height: 1.25;
}

h6 {
  margin: 0 0 .25em;

  color: $font-color;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.1;
}

blockquote {
  font-family: inherit;
  font-size: 0.95em;
  line-height: 1.4;
}

table {
  border-collapse: collapse;
}
sup,
sub {
  font-size: 80%;
}
p {
  margin: 0;
  padding: 0 0 1em;
}
ul,
ol {
  margin: 0;
  padding: 0 0 1em;
}

ul ul,
ol ol {
  padding: 0;
}

ul li {
  margin: 0 0 0.5rem 2rem;
  padding: 0;
  list-style-type: disc;
}

ul li * {
  margin-top: 0;
}
ul li p {
  margin: 0;
  padding: 0;
}
ol li {
  margin: 0 0 0.5rem 2rem;
}
a,
a:link,
a:visited {
  color: $link-color;
  font-weight: 500;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  transition: color 0.2s ease-out,
            background 0.2s ease-in,
            opacity 0.2s ease-out,
            border 0.2s ease-out;
}
a:hover,
a:active,
a:focus {
  color: $hover-color;
  border-color: $hover-color;
  text-decoration: none;
}
a:active, a:focus {
  outline: rgb(94, 158, 215) auto 5px;
}

img {
  width: auto;
  max-width: 100%;
}
strong {
  font-weight: 500;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $hr-color;
  margin: 1em 0;
  padding: 0;
  clear: both;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input,
textarea,
select {
  font-family: $main-font;
  font-size: 1em;
}

figure {
  margin: 0;
}
figcaption {
  display: block;
}

::-moz-selection {
  background: $highlight-color;
  text-shadow: none;
}
::selection {
  background: $highlight-color;
  text-shadow: none;
}
