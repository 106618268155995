.form {
  border-top: 1px solid $color-blue-light;

}
.freeform-column {
  text-align: left;

  h2 {
    margin: 1em 0 0;
  }
  label {
    display: block;
    padding: .5em 0;

    font-size: 1em;
    line-height: 1.5;
  }
  .freeform-instructions {
    margin-top: -5px !important;
    color: inherit !important;
  }
  input[type="text"], input[type="email"], input[type="tel"], textarea, select, input[type="url"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: .75em 1em;
    margin: 0 0 .5em;

    border: 1px solid $color-grey-dark;
    border-radius: 2px;
    transition: all .1s ease-out;

    width: 100%;
  }
  select {
    background: url($image-path + 'icon-chevron-down.svg') no-repeat right white;
    background-position: calc(100% - 12px) 16px;
  }
  button {
    display: inline-block;
    outline: none;
    cursor: pointer;

    padding: 1em 4em;
    margin: 0 auto 15px;

    background: linear-gradient(to right, $color-blue, $color-blue-gradient, $color-navy);
    background-size: 250% 100%;
    border: none;
    border-radius: 2px;

    transition: transform .2s;
    text-align: center;

    color: $color-white !important;
    font-size: 1em;
    font-family: $main-font;
    font-weight: normal;
    line-height: 1.25;
    text-decoration: none;
    transition: .2s all;

    &:hover, &:active, &:focus {
      background: linear-gradient(to right, $color-blue, $color-blue-gradient, $color-navy);
      background-size: 120% 100%;

      box-shadow: 0px 1px 5px rgba($color-black, .2),
                  0px 2px 45px rgba($color-black, .1);
    }
  }
}
