.footer {
  border-top: 1px solid $color-blue-light;
  padding: 2.5em 0;

  font-size: .825em;
  text-align: center;

  p {
    margin: 0;
    padding: 0;
  }
  ul {
    display: flex;
    justify-content: center;

    margin: 0;
    padding: 0;

    li {
      margin: 0 5px;
      padding: 0;
      list-style-type: none;
    }
  }
  a {
    &, &:link, &:visited {
      color: inherit;
      font-weight: inherit;
      border-bottom: 3px solid $color-blue;
    }
    &:hover, &:active, &:focus {
      color: $hover-color;
      border-color: $hover-color;
    }
  }

  .footerContainer {
    width: 90%;
    margin: 0 auto;
    text-align: center;

    @media screen and (min-width: $medium-screen) {
      display: flex;
      justify-content: center;

      .footerLeft {
        width: 50%;
        display: flex;
        text-align: left;
      }

      .footerRight {
        width: 50%;
        text-align: right;
      }
    }
    .footerLeft {
      width: 100%;
      margin-bottom: 1em;
    }

    .footerRight {
      width: 100%;
    }
  }
}
