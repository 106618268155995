
a.button, a.button:link, a.button:visited,
.button a, .button a:link, .button a:visited,
input.button, button.button {
  display: inline-block;
  outline: none;
  cursor: pointer;

  padding: .75em 2.5em;
  margin: 0px;

  background: $color-blue-light;
  border: none;
  border-radius: 2px;

  transition: transform .2s;
  text-align: center;

  color: $color-grey-dark !important;
  font-size: 1em;
  font-family: $main-font;
  font-weight: normal;
  line-height: 1.25;
  text-decoration: none;
  transition: .2s all;

  &.apply-btn {
    padding: 1em 3em;
    margin: 0 0 15px;
    background: linear-gradient(to right, $color-blue, $color-blue-gradient, $color-navy);
    background-size: 250% 100%;
    border: 0 !important;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05),
                0px 2px 20px rgba(0, 85, 184, 0.1),
                0px 4px 50px rgba(0, 0, 0, 0.05),
                0px 4px 100px rgba(0, 85, 184, 0.1);

    color: $color-white !important;

    img {
      width: 20px;
      height: 20px;
      margin-left: 5px;
    }
    @media all and (min-width: $small-screen) {
      margin: 0 10px 25px;
    }
  }
  &.sm-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: .9em;
    padding: 1em 2.5em;

    img {
      height: 20px;
      width: 20px;
      margin-left: 15px;
    }
  }

  &:hover, &:active, &:focus {
    background: $hover-color;
    box-shadow: 0px 1px 5px rgba($color-black, .2),
                0px 2px 45px rgba($color-black, .1);
    color: $color-white;

    &.apply-btn {
      background: linear-gradient(to right, $color-blue, $color-blue-gradient, $color-navy);
      background-size: 120% 100%;
    }
    &.sm-btn {
      background: $color-blue;
    }
  }
  &:disabled {
    cursor: initial;
    background: $color-grey-light !important;
    color: $color-grey !important;
    transform: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}
a.button.action-btn, .button.action-btn a {
  &, &:link, &:visited {
    padding: 1em 2.5em;
    margin: 0 0 15px;
    background: linear-gradient(to right, $color-blue, $color-blue-gradient, $color-navy);
    background-size: 250% 100%;
    border: 0 !important;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05),
                0px 2px 20px rgba(0, 85, 184, 0.1),
                0px 4px 50px rgba(0, 0, 0, 0.05),
                0px 4px 100px rgba(0, 85, 184, 0.1);

    color: $color-white !important;
    font-size: 1rem;
  }
  &:hover, &:active, &:focus {
    background: linear-gradient(to right, $color-blue, $color-blue-gradient, $color-navy);
    background-size: 125% 100%;
  }
}

a.arrow-link, a.arrow-link:link, a.arrow-link:visited,
.arrow-link a, .arrow-link a:link, .arrow-link a:visited{
  display: flex;

  border: 0;
  font-weight: normal;

  &:after {
    display: inline-block;
    content: url($image-path + "icon-arrow-right.svg");
    margin-left: 5px;
    transition: transform .2s ease-in;
  }

  &:hover, &:active, &:focus {
    &:after {
      transform: translateX(3px);
    }
  }
}

a.arrow-link.dark-arrow, .arrow-link.dark-arrow a {
  &, &:link, &:visited {
    &:after {
      content: url($image-path + "icon-arrow-blue.svg");
    }
  }
  &:hover, &:active, &:focus {
  }
}

.right-arrow {
  p a {
    &:after {
      display: inline-block;
      content: url($image-path + "icon-arrow-navy.svg");
      margin-left: 5px;
      transition: transform .2s ease-in;
    }

    &:hover, &:active, &:focus {
      &:after {
        transform: translateX(3px);
      }
    }
  }
}
