.job-listing {}
.no-results {
  margin: 0;
  padding: 1em;
  text-align: center;
}
.job {
  display: flex;
  align-items: center;

  &:nth-of-type(even) {
    background: $color-offwhite;
  }
  h2 {
    margin: 0;
    font-size: 1.2em;
    font-weight: 500;
    font-family: $main-font;

    img {
      height: 20px;
      width: 20px;
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
  .job-name {
    flex: 1;
    padding: 1rem 2rem;
  }
  .job-info {
    flex: 0 1 25%;
    padding: 1rem 0;

    p {
      line-height: 1.4;
      margin: 0 0 .5em;

      &:last-child {
        margin: 0
      }
    }
  }
  .job-details {
    flex: 0 1 150px;
    padding: 1rem 2rem;
  }

  @media all and (max-width: $small-screen) {
    flex-wrap: wrap;
    padding: .75em 5vw;

    .job-name {
      flex: 1;
      padding: 1rem 0;
      font-size: 1em;
    }
    .job-info {
      flex: 0 1 45%;
      padding: 1rem 0;
    }

    .job-details {
      flex: 0 0 100%;
      padding: .5rem 0;
    }
  }
}
.company {
  font-size: 1.2em;
}
.job-tags {
  
  @media screen and (min-width: $small-screen) {    
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.posted, .industry, .location {
  display: flex;

  font-size: .9em;

  &:before {
    display: block;
    content: '';
    flex: 0 0 25px;
    margin-right: 5px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.posted {
  &:before {
    background-image: url($image-path + 'icon-time.svg');
  }
}
.industry {
  &:before {
    background-image: url($image-path + 'icon-industry.svg');
  }
}
.location {
  &:before {
    background-image: url($image-path + 'icon-location.svg');
  }
}

.statistics {
  .container {
    background: linear-gradient(to right, $color-blue, $color-blue-gradient);
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05),
                0px 2px 20px rgba(0, 85, 184, 0.1),
                0px 4px 50px rgba(0, 0, 0, 0.05),
                0px 4px 100px rgba(0, 85, 184, 0.1);
    border-radius: 2px;
    color: $color-white;
  }
}
.stat-list {
  margin: 0;
  padding: 0;

  display: flex;
  flex-wrap: wrap;

  li {
    margin: 0;
    padding: 1.25em 2em 1.75em;
    list-style-type: none;
    line-height: 1.4;

    flex: 0 0 50%;
    text-align: center;
    border-bottom: 1px solid rgba($color-white, .15);

    &:nth-child(even) {
      border-left: 1px solid rgba($color-white, .15);
    }

    @media all and (min-width: $medium-screen) {
      flex: 1;
      border-bottom: 0;
      border-left: 1px solid rgba($color-white, .15);

      font-size: 1.2em;

      &:first-child {
        border: 0px;
      }
    }
  }

  strong {
    display: block;

    font-size: 3.5em;
    font-family: $accent-font;
    line-height: .9;

    @media all and (min-width: $small-screen) {
      font-size: 4.75em;
    }

    &:after {
      display: block;
      content: '';
      margin: 0 auto 12px;

      background: $color-blue-light;
      height: 5px;
      width: 65px;
    }
  }
}
.feature-grid {
  display: flex;
  flex-wrap: wrap;
}
a.feature-card {
  background: $color-navy;
  border-radius: 2px;
  padding: .5em;
  margin: .5em 1%;
  border: 1px solid transparent;

  transition: .2s all;

  flex: 0 1 48%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media all and (min-width: $small-screen) {
    flex: 0 1 23%;
    padding: 1em 2em;
  }

  figure {
    margin: 1em 0 1.5em;
    min-height: 90px;

    display: flex;
    justify-content: center;
    align-items: center;;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10 & IE11 CSS styles go here */
      height: auto !important;
      min-height: auto !important;
    }

    @media all and (min-width: $small-screen) {
      margin: 1.5em 0 2em;
      min-height: 125px;
    }
  }
  p {
    margin: 0;
    padding: 0;
    display: flex;
    border: 0;
    font-weight: normal;

    &:after {
      display: inline-block;
      content: url($image-path + "icon-arrow-right.svg");
      margin-left: 5px;
      transition: transform .2s ease-in;
    }

  }

  &.text-card {
    border: 1px solid $color-blue-dark;
    background: $color-blue-darker;
    padding: 1em;

    h3 {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      font-family: $main-font;
      font-weight: 700;
      color: white;
    }
  }

  &:hover, &:active, &:focus {
    background: $color-blue-darker;
    box-shadow: 0px 1px 40px rgba($color-navy, .75);
    border: 1px solid $color-navy;
    border-radius: 4px;

    &.text-card {
      background: $color-navy;
    }

    & p {
      color: $color-blue-light;
    }

    & p:after {
      transform: translateX(3px);
    }
  }
}



// Loading screen
.search-listing {
  position: relative;
}
.loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;

  background: rgba($color-white, .95);
  text-align: center;
  padding: 50px;
  margin: 0 auto;
  max-width: $max-width;

  display: none;
}
.loading-text {
  display: block;
  text-transform: uppercase;
  color: $color-black;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid $highlight-color;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $highlight-color transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
