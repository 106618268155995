.intro {
  margin-bottom: 2.5em;
  text-align: center;
  
  .container {
    max-width: 875px;
  }

  a {
    &, &:link, &:visited {
      border-bottom: 3px solid $color-blue;
      color: inherit;
      font-weight: inherit;
    }
    &:hover, &:active, &:focus {
      color: $hover-color;
      border-color: $hover-color;
    }
  }
}