.header {
  padding: 2em 0;
  text-align: center;

  .container {
    max-width: initial;

    @media all and (min-width: $small-screen) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .logo {
    margin-bottom: 2em;

    @media all and (min-width: $small-screen) {
      margin-bottom: 0;
      margin-right: 2em;
    }
    img {
      width: 212px;
      max-width: 100%;
    }
    a {
      &, &:link, &:visited {
        border: 0;
      }
    }

    @media (max-width: $xsmall-screen) {
      margin: 1.125em 0 2.125em 0;

      img {
        width: 180px;
        max-width: 100%;
        transform: translateX(-7px);
      }
    }
  }
  .main-nav {
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;

      @media (max-width: $xsmall-screen) {
        border-top: 1px solid $color-blue-light;
        border-bottom: 1px solid $color-blue-light;
        padding: .375em 0 0em;
      }

      li {
        margin: 0 1.75em;
        padding: 0;
        list-style-type: none;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    a {
      &, &:link, &:visited {
        border-bottom: 5px solid transparent;
        display: inline-block;

        color: $color-grey-dark;
        font-weight: 600;
      }
      &:hover, &:active, &:focus {
        color: $color-blue-dark;
      }

      @media (max-width: $xsmall-screen) {
        line-height: 1.2;
      }
    }
    .active > a {
      &, &:link, &:visited {
        border-color: $color-blue-light;
      }
    }
  }
  .browse {
    margin-left: auto;
  }

  #translate {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: $xsmall-screen) {
      left: 50%;
    }

    .goog-te-gadget {
      font-size: 4px;
      color: transparent;
      line-height: 0;

      select.goog-te-combo {
        position: relative;
        display: block;
        padding: 0 8px;
        height: 22px;
        margin: 0;

        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: $color-blue-light;
        border: 1px solid transparent;
        border-radius: 2px;
        box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.08), 0px 4px 36px rgba(0, 85, 184, 0.02), 0px 2px 12px rgba(49, 56, 80, 0.04);

        font-size: 12px;

        @media (max-width: $xsmall-screen) {
          width: 170px;
          transform: translateX(-85px);
        }
      }

      select {
        background: url($image-path + 'icon-chevron-down.svg') no-repeat right white;
        background-position: calc(100% - 10px) 6px !important;
      }
    }

    span {
      display: none !important;
    }
  }
}
