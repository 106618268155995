.fadein {
  animation: fadein .2s linear;
}

@keyframes fadein {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.fadeup {
  animation: fadeup .4s linear;
}

@keyframes fadeup {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(0%);
  }
  50% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(-50%);
  }
}


.bounce {
  animation: bounce 1.9s infinite;
}
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-8px);
  }

  60% {
    transform: translateY(-4px);
  }
}


@keyframes menufade {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: translateY(48px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}