$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$white: #eee;

// scaling... any units
$width: 100px;

.loader-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba($color-blue-light, .8);
  display: none;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  .loader-text {
    font-family:$accent-font;
    font-size: 2em;
    color: $color-grey;
  }
}

.loader {
  position: relative;
  margin: 0 auto;
  width: $width;
  margin-bottom: 2em;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $color-blue-pale;
  }
  40% {
    stroke: $color-blue;
  }
  66% {
    stroke: $color-blue-dark;
  }
  80%,
  90% {
    stroke: $color-blue-gradient;
  }
}

.loader-wrapper.visible {
  display: flex;
}