.largetext {
  font-size: 1.25em;
  line-height: 1.65;
}
.results-intro {
  background: $color-grey-dark;
  border-radius: 2px;
  padding: .75rem 2rem;

  color: $color-white;
  font-size: .9em;

  @media (max-width: $medium-screen) {
    padding: .75rem 5vw;
  }
  @media screen and (min-width: $small-screen) {    
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    margin: 0;
    padding: 0;
  }
}
.date {
  font-size: .85em;
  text-transform: uppercase;
}


.featured, .skillsRI {
  display: flex;
  align-items: center;

  border-radius: 2px;
  padding: 5px 10px;
  margin: 10px 5px 5px 0;

  font-size: .8em;
  line-height: 1;

  img {
    margin-right: 5px;
  }
}
.featured {
  background: $color-blue-light;
  color: $color-blue-darker;
}
.skillsRI {
  background: $color-blue-dark;
  color: $color-white;
}