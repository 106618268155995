#main {

  @media all and (min-width: $small-screen) {
    padding: 2.5em 0;
    min-height: calc(100vh - (5em + 150px));
  }
}
.content {
  margin-bottom: 3em;

  .container {
    background: $color-white;
    box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.08),
                0px 4px 36px rgba(0, 85, 184, 0.02),
                0px 2px 12px rgba(49, 56, 80, 0.04);
    border-radius: 2px;

    padding: 2em;
  }
}
.search-listing {
  margin-bottom: 3em;
  .container {
    background: $color-white;
    box-shadow: 0px 8px 64px rgba(0, 0, 0, 0.08),
                0px 4px 36px rgba(0, 85, 184, 0.02),
                0px 2px 12px rgba(49, 56, 80, 0.04);
    border-radius: 2px;
    width: 100%;
  }
}
