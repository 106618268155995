
.caption {
	margin: 0 0 1.5em;

	color: rgba($font-color, .9);
	font-size: .825em;
	font-family: $accent-font;
	font-weight: 200;
	line-height: 1.5;

	.caption-text {
		display: block;
		padding: 1em 0;
	}

	@media (min-width: $xsmall-screen) {
		max-width: 33%;

		&.left	{
			float: left;
			clear: left;
			margin: 0 4% 1em 0;
		}
		&.right	{
			float: right;
			clear: right;
			margin: 0 0 1em 4%;
		}
		&.full {
			max-width: 100%;
			margin: 0 0 1em;
		}
	}
}

